#plannerContainer {
  width: 90%;
  position: relative;
  padding: 0px 30px 30px 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

#mapContainer {
  height: 600px;
  width: 100%;
  margin: auto;
  max-width: 100%;
  border-radius: 10px;
}

#startSearch {
  position: absolute;
  color: #00213C;
  font-family: Nunito;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.52px;
  line-height: 33px;
}

#plannerBodyContainer {
  position: relative;
  top: 60px;
  z-index: 1;
}

#plannerCard {
  width: 22vw;
  min-width: 400px;
  max-width: 950px;
  position: absolute;
  left: 4vw;
  top: 6vw;
  z-index: 2;
  border-radius: 7px;
}

.ant-form-item {
  margin: 0px !important;
}

#travelContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#stepsContainer {
  margin-top: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1750px) {
  .ant-calendar-picker-icon {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  #plannerContainer {
    padding: 0px;
  }

  #plannerCard {
    top: 20px;
    width: 100%;
    left: 0;
  }

  #startSearch {
    width: 100%;
    text-align: center;
  }

  #mapContainer {
    position: relative;
    top: 520px;
    min-width: 100%;
    min-height: 100%;
  }

  #stepsContainer {
    margin-top: 550px;
  }
}

@media only screen and (max-width: 450px) {

  #plannerContainer {
    min-height: 920px;
  }

  #plannerContainer{
    padding: 5px;
  }

  #plannerCard {
    min-width: 250px;
    width: 100%;
  }
}