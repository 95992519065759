#telechargerApplication {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: auto;
    bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
}

/* #footerHeader {
    position: relative;
    z-index: 2;
    color: #00213C;
    font-family: Nunito;
    font-size: 37px;
    font-weight: 800;
    width: 80%;
    margin-bottom: -50px;
} */

#buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    margin-bottom: 4vw;
}

.downloadButtons {   
    margin: 15px;
    display: inline-flex;
    min-width: 210px;
    align-items: center;
    justify-content: center;
    padding: 1rem 3rem;
    border-radius: 60px;
    font-size: 1.25rem;
    font-weight: 700;
    color: #fff;
    background-color: #00213c;
}

.downloadButtons img {
    margin-right: .5rem;
}

.downloadButtons:hover {
    color: white;
    background-color: #21d1c5 !important;
}

.logos {
    height: 30px;
    width: 30px;
    color: white;
}

#footerTextDownload {
    font-size: 20px;
    color: #00213c;
    font-weight: 700;
    font-family: Nunito;
    margin-bottom: 4vw;
    margin-top: 40px;
    width: 80%;
}

/* #heroMediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} */

#footerDivider {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    border: 0;
    height: 1px;
    background-color: #ddd;
    width: 80%;
}

.footer__logos {
    align-items:center;
    display:flex;
    font-family:Nunito, Tahoma, Geneva, sans-serif;
    font-size:16px;
    height:115.594px;
    justify-content:center;
}

.footer__logos-container {
    align-items:center;
    border-left:1px solid rgb(221, 221, 221);
    display:flex;
    flex-direction:row;
    font-family:Nunito, Tahoma, Geneva, sans-serif;
    margin-bottom:0px;
    margin-left:32px;
    margin-right:0px;
    margin-top:25.6px;
    padding-bottom:0px;
    padding-left:32px;
    padding-right:0px;
    padding-top:0px;
    text-align:center;
}

#footnotes {
    text-align: left;
    width: 80%;
}

@media only screen and (max-width: 1000px)  {
    .footer__logos {
        height:385.594px;
    }

    .footer__logos-container {
        align-items:center;
        border-left: 1px solid rgb(221, 221, 221);
        flex-direction:column;
        height:360px;
        width:179px;
    }
}

@media only screen and (max-width: 575px) {
    #itinHeader {
      display: none;
    }

    /* #footerHeader {
        font-size: 20px;	
        margin-bottom: 0px;
    } */
}

@media only screen and (max-width: 460px) {
    .footer__logos {
        display:block;
        margin-bottom:25.6px;
        margin-top:25.6px;
    }

    .footer__logos-container {
        height:408px;
        border-left: 0px;
        margin-left: 0px;
        padding-left: 0px;
    }
}