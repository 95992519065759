#introContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* #city {
  width: 80%;
  max-width: 1400px;
  position: relative;
  margin: auto;
} */
  
/* #coupleOnPhone {
  height: 30vw;
  width: 15vw;
  max-width: 200px;
  position: absolute;
  top: 5vw;
  left: 5vw;
} */

/* #ladyWalking {
  height: 28vw;
  width: 21vw;
  max-width: 250px;
  position: absolute;
  top: 8vw;
  right: 1vw;
} */

#introBodyTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  position: relative;
  z-index: 1;
  margin: auto;
  width: 50%;
  top: 50px;
  text-align: center;
  font-size: 14px;
}

#introHeaderText {
  color: #00213C;
  font-family: Nunito;
  font-size: 44px;
  font-weight: 800;
}

#startButton {
  border-radius: 60px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  min-width: 130px;
  min-height: 45px;
  z-index: 10;
}

#rowDivider {
  position: relative;
  height: 40px;
  z-index: 0;
}

@media only screen and (max-width: 1500px) {
  /* #coupleOnPhone {
    width: 12vw;
  } */
  
  /* #ladyWalking {
    width: 15vw;
  } */
}

@media only screen and (max-width: 1400px) {

  #introBodyTextContainer {
    width: 60%;
  }

  #introHeaderText {
    font-size: 30px;
  }

  #introBodyText {
    font-size: small;
    /* TODO: if images put back in, use min-height */
    /* min-height: 150px; */
  }
}

@media only screen and (max-width: 875px) {

  #introBodyTextContainer {
    top: 0px;
    width: 80%;
  }

  /* #city {
    width: 100%;
  } */

  /* #coupleOnPhone {
      display: none;
  } */

  /* #ladyWalking {
      display: none;
  } */

  #rowDivider {
    height: 20px;
  }

}

@media only screen and (max-width: 680px) {
  #startButton {
    display: none;
  }

  #rowDivider {
    height: 5px;
  }
}

@media only screen and (max-width: 475px) {
  #introContainer {
    min-height: 200px;
  }
}

@media only screen and (max-width: 405px) {
  #introContainer {
    min-height: 250px;
  }
}

@media only screen and (max-width: 381px) {
  #introHeaderText {
    font-size: 20px;
  }
}

@media only screen and (max-width: 351px) {
  #introContainer {
    min-height: 250px;
  }
}